<script>
// @ts-nocheck
import MetisMenu from "metismenujs/dist/metismenujs";

export default {
  components: {},
  computed: {
    type: function () {
      return this.$store.getters["users/type"];
    },
  },
  mounted: function() {
    document.body.setAttribute("data-sidebar", "dark");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
    lightSidebar() {
      document.body.setAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-topbar", "dark");
    },
    iconSidebar() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar", "colored");
      document.body.setAttribute("data-sidebar", "dark");
      document.body.removeAttribute("data-topbar", "dark");
    },
    coloredSidebar() {
      document.body.setAttribute("data-sidebar", "colored");
      document.body.removeAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <!-- <li class="menu-title">Menu</li>

      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="badge badge-pill badge-info float-right">3</span>
          <span>Dashboard</span>
        </router-link>
      </li> -->

      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle text-primary"></i>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li class="menu-title">Apps</li>

      <li v-show="type == 'USER'">
        <router-link tag="a" to="/soal" class="side-nav-link-ref">
          <i class="bx bx-file text-primary"></i>
          <span>Soal</span>
        </router-link>
      </li>
      <!-- Using value -->
      <b-button v-b-toggle="'collapse-2'" class="btn btn-secondary btn-block collapsed">Soal Pondok</b-button>

      <!-- Element to collapse -->
      <b-collapse id="collapse-2">
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalBacaQuran" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Baca Quran</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalPsikotes" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Psikotes</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalPraktekIbadah" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Qoliyah Amaliyah</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalHafalanJuz" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Hafalan Juz</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalBerhitungSoal" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Berhitung Soal</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalBerhitungAngka" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Berhitung Angka</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalBahasaIndonesia" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Bahasa Indonesia</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalHukumTajwids" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Hukum Tajwids</span>
          </router-link>
        </li>
        <li v-show="type == 'USER'">
          <router-link tag="a" to="/soalImla" class="side-nav-link-ref">
            <i class="bx bx-file text-primary"></i>
            <span>Soal Imla</span>
          </router-link>
        </li>
      </b-collapse>

      <li v-show="type == 'USER'">
        <router-link tag="a" to="/paket" class="side-nav-link-ref">
          <i class="bx bx-food-menu text-primary"></i>
          <span >Paket Tryout</span>
        </router-link>
      </li>
      <li  v-show="type == 'GURU'" >
        <router-link tag="a" to="/koreksi" class="side-nav-link-ref">
          <i class="bx bx-book text-primary"></i>
          <span >Koreksi</span>
        </router-link>
      </li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/murid" class="side-nav-link-ref">
          <i class="bx bx-group text-primary"></i>
          <span>Data Murid</span>
        </router-link>
      </li>
      <li  v-show="type == 'USER'" class="menu-title">Module Master</li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/guru" class="side-nav-link-ref">
          <i class="bx bx-group text-primary"></i>
          <span>Data Guru</span>
        </router-link>
      </li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/area" class="side-nav-link-ref">
          <i class="bx bx-map text-primary"></i>
          <span>Area</span>
        </router-link>
      </li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/jenjang" class="side-nav-link-ref">
          <i class="bx bx-bar-chart text-primary"></i>
          <span>Jenjang</span>
        </router-link>
      </li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/matpel" class="side-nav-link-ref">
          <i class="bx bxs-book text-primary"></i>
          <span>Mata Pelajaran</span>
        </router-link>
      </li>
      <li v-show="type == 'USER'" class="menu-title">Reports</li>
      <li v-show="type == 'USER'">
        <router-link tag="a" to="/calendar" class="side-nav-link-ref">
          <i class="bx bx-calendar text-danger"></i>
          <span>Calendar Reports</span>
        </router-link>
      </li>
      <!-- <li>
        <router-link tag="a" to="/charts/apex" class="side-nav-link-ref">
          <i class="bx bx-file"></i>
          <span>editor</span>
        </router-link>
      </li> -->
    </ul>
  </div>
  <!-- Sidebar -->
</template>
